.simpleTabViewer__filePickerButton {
  padding: 0.6ch;
  background-color: #efefef;
  border-radius: 3px;
  -webkit-transition: 200ms ease-in-out;
  -o-transition: 200ms ease-in-out;
  transition: 200ms ease-in-out;
  border: none;
  border-radius: 4px;
}

.simpleTabViewer__filePickerButton:hover {
  background-color: #bde4fe;
}

.simpleTabViewer__filePickerButton.simpleTabViewer__selected {
  background-color: var(--archBlue-200);
  border: 1px solid gray;
}

.simpleTabViewer__tabContainer {
  padding: 5px;
  margin: 5px;
  display: -ms-flexbox;
  display: flex;
  gap: 0.3rem;
  overflow-x: scroll;
  scrollbar-width: thin; /* firefox */
  white-space: nowrap;
  position: sticky;
  top: -1px;
  z-index: 2;
  background-color: white;
}

/* chrome, safari, opera */
.simpleTabViewer__tabContainer::-webkit-scrollbar {
  height: 2px;
}
