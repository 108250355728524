body,
h1,
h2,
h3,
h4,
h5 {
  font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
}

a:hover {
  cursor: pointer;
}

html {
  font-size: 14px;
  line-height: 20px;
}

h1 {
  line-height: 1.28em;
}

h5 {
  margin: 0;
  font-size: 1rem;
}

p {
  line-height: 1.42em;
}

img,
svg {
  vertical-align: middle;
}

:root {
  --archBlue-400: #2a80b9;
  --archBlue-200: #9bcae8;

  /* used for misc viewport hacks */
  --header-height: 58px;
  --footer-height: 60px;
  --sidebar-width: 0;
}

@media (min-width: 400px) {
  :root {
    --sidebar-width: 52px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

/* outermost container for portal and funds, creating the "app-like" look */
.App__container {
  position: absolute;
  height: calc(100vh - var(--header-height) - var(--footer-height));
  overflow-y: scroll;
  top: var(--header-height);
  bottom: var(--footer-height);
  left: var(--sidebar-width);
  right: 0;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
