/* Adapted from https://codepen.io/t_afif/pen/MWpKbez */
.dots-left-to-right {
  width: 20px;
  height: 5px;
  margin-top: 5px;
  background: -webkit-radial-gradient(circle closest-side, rgba(0, 0, 0, 0.95), #0000) 0/33.3%
    100% space;
  background: -o-radial-gradient(circle closest-side, rgba(0, 0, 0, 0.95), #0000) 0/33.3%
    100% space;
  background: radial-gradient(circle closest-side, rgba(0, 0, 0, 0.95), #0000) 0/33.3%
    100% space;
  -webkit-clip-path: inset(0 100% 0 0);
          clip-path: inset(0 100% 0 0);
  -webkit-animation: dots-l2r 1s steps(4) infinite;
          animation: dots-l2r 1s steps(4) infinite;
}

@-webkit-keyframes dots-l2r {
  to {
    -webkit-clip-path: inset(0 -34% 0 0);
            clip-path: inset(0 -34% 0 0);
  }
}

@keyframes dots-l2r {
  to {
    -webkit-clip-path: inset(0 -34% 0 0);
            clip-path: inset(0 -34% 0 0);
  }
}

.dots-bouncing {
  width: 20px;
  height: 10px;
  margin-bottom: -5px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #000 30%, #0000);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  -webkit-animation: bounce-dots 1s infinite linear;
          animation: bounce-dots 1s infinite linear;
}

@-webkit-keyframes bounce-dots {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}

@keyframes bounce-dots {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}
