/*
Change in system caused pdf toolbar to not be sticky
when scrolling -- this change overrides the react pdf viewer
built in css attributes
*/
.rpv-default-layout__body {
  padding-top: 0 !important;
  overflow: unset !important;
}

.rpv-default-layout__toolbar {
  position: sticky !important;
}

/**
 * Bug in the @react-pdf-viewer library with setting `SpecialZoomLevel.PageWidth`
 * on long enough documents to trigger lazy loading which also have multiple
 * orientations or sizes of pages which causes a zoom in-out loop.
 *
 * Fix taken from: https://github.com/react-pdf-viewer/react-pdf-viewer/issues/1471
 */
.rpv-core__inner-pages {
  scrollbar-gutter: stable;
}
