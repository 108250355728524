.sliding-banner-colorful {
  white-space: nowrap;
  pointer-events: none;
  -webkit-animation: bannermove 10s linear infinite, colorchange 5s infinite;
          animation: bannermove 10s linear infinite, colorchange 5s infinite;
}

@-webkit-keyframes bannermove {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100vw);
            transform: translateX(-100vw);
  }
}

@keyframes bannermove {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100vw);
            transform: translateX(-100vw);
  }
}

@-webkit-keyframes colorchange {
  0% {
    color: black;
  }
  10% {
    color: red;
  }
  20% {
    color: orange;
  }
  30% {
    color: yellow;
  }
  40% {
    color: green;
  }
  50% {
    color: blue;
  }
  60% {
    color: indigo;
  }
  70% {
    color: purple;
  }
  80% {
    color: magenta;
  }
  90% {
    color: cyan;
  }
  100% {
    color: aliceblue;
  }
}

@keyframes colorchange {
  0% {
    color: black;
  }
  10% {
    color: red;
  }
  20% {
    color: orange;
  }
  30% {
    color: yellow;
  }
  40% {
    color: green;
  }
  50% {
    color: blue;
  }
  60% {
    color: indigo;
  }
  70% {
    color: purple;
  }
  80% {
    color: magenta;
  }
  90% {
    color: cyan;
  }
  100% {
    color: aliceblue;
  }
}
