/* The tildes here are necessary, otherwise our CSS loader can't resolve these paths */
@import "~@radix-ui/colors/black-alpha.css";
@import "~@radix-ui/colors/mauve.css";

.ContextMenuTrigger {
  display: block;
  border: 2px white dashed;
  color: white;
  border-radius: 4px;
  font-size: 15px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: 45px 0;
  width: 300px;
  text-align: center;
}

.ContextMenuContent,
.ContextMenuSubContent {
  z-index: 9999;
  min-width: 220px;
  background-color: white;
  border-radius: 6px;
  overflow: hidden;
  padding: 5px;
  -webkit-box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
          box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
}

.ContextMenuItem,
.ContextMenuCheckboxItem,
.ContextMenuRadioItem,
.ContextMenuSubTrigger {
  font-size: 13px;
  line-height: 1;
  color: var(--black-a12);
  border-radius: 3px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  height: 25px;
  padding: 0 5px;
  position: relative;
  padding-left: 25px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  outline: none;
}
.ContextMenuSubTrigger[data-state="open"] {
  background-color: var(--black-a3);
  color: var(--black-a12);
}
.ContextMenuItem[data-disabled],
.ContextMenuCheckboxItem[data-disabled],
.ContextMenuRadioItem[data-disabled],
.ContextMenuSubTrigger[data-disabled] {
  color: var(--mauve-8);
  pointer-events: "none";
}
.ContextMenuItem[data-highlighted],
.ContextMenuCheckboxItem[data-highlighted],
.ContextMenuRadioItem[data-highlighted],
.ContextMenuSubTrigger[data-highlighted] {
  background-color: var(--black-a2);
  color: var(--black-a10);
  cursor: pointer;
}

.ContextMenuLabel {
  padding-left: 25px;
  font-size: 12px;
  line-height: 25px;
  color: var(--mauve-11);
}

.ContextMenuSeparator {
  height: 1px;
  background-color: var(--black-a8);
  margin: 5px;
}

.ContextMenuItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}

.RightSlot {
  margin-left: auto;
  padding-left: 20px;
  color: var(--mauve-11);
}
[data-highlighted] > .RightSlot {
  color: var(--black-a12);
}
[data-disabled] .RightSlot {
  color: var(--mauve-8);
}
